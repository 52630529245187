<template>
  <v-container
    fluid
    tag="section"
  >
    <v-data-table
      :headers="[
        { text: 'IP', value: 'ip' },
        { text: 'verbunden seit', value: 'connecttime' },
        { text: 'aktuell', value: 'currentview' },
        { text: 'angemeldet als', value: 'person' }
      ]"
      :items="ClientFind"
      :items-per-page="50"
    >
      <template #item.person="{item}">{{ item.person | person }}</template>
    </v-data-table>
  </v-container>
</template>

<script>
import { apolloDefault, useGraphQL } from '@/plugins/graphql'

const query = `
  _id
  ip
  connecttime
  person { familyName givenName }
  currentview
`

export default {
  name: 'server',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  data: () => ({
    ClientFind: []
  }),

  apollo: apolloDefault('Client', query, '(connected: true)')
}
</script>

<style scoped>

</style>
